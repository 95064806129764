@font-face {
  font-family: Raleway;
  src: url("Raleway.0a6c83ff.ttf");
}

body {
  color: #fff;
  background: #000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Raleway;
  font-size: 20px;
  overflow: hidden;
}

body * {
  box-sizing: border-box;
  will-change: filter, transform;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin-bottom: 1em;
}

p {
  width: inherit;
  word-wrap: break-word;
  margin-bottom: 1em;
}

a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: all .4s;
}

a:hover {
  text-shadow: .1em 0 .4em red;
}

.wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;
  overflow: hidden scroll;
}

.section {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: relative;
  inset: 0;
  overflow: hidden;
}

.logo {
  max-width: 100%;
  padding-bottom: 18.3%;
  position: relative;
}

.logo:after {
  content: "";
  background-image: url("dammy_logo.fea33658.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  position: absolute;
  inset: 0;
}

.upper {
  text-transform: uppercase;
}

#section1 {
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  padding: 1vh 4vw;
  display: flex;
}

#section1 .background {
  background-image: url("dammy_bg.0f1516f7.png");
  background-size: cover;
  position: absolute;
  inset: 0;
}

#section1 .dammy {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width <= 599px) {
  #section1 .dammy {
    max-width: 120vw;
  }
}

#section1 .right {
  text-align: right;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 80%;
  max-height: 700px;
  display: flex;
  position: relative;
  transform: translateX(400%);
}

@media (width <= 599px) {
  #section1 .right {
    text-align: center;
    align-items: center;
    width: 100%;
    height: 55%;
    margin: -10% auto 0;
    transform: none;
  }
}

#section1 .right .logo-wrapper {
  width: 100%;
}

#section1 .right .subline {
  font-family: Raleway;
  font-size: 2em;
  font-weight: 100;
}

#section1 .social {
  align-items: center;
  height: auto;
  display: flex;
}

@media (width <= 599px) {
  #section1 .social {
    justify-content: center;
  }
}

#section1 .social-link {
  color: #0000;
  width: 3em;
  height: 3em;
  margin: .5em;
  transition: all .4s;
  position: relative;
}

#section1 .social-link:hover {
  transform: scale(1.1);
}

#section1 .social-link-spotify:after {
  content: "";
  background-image: url("spotify.01898414.svg");
  background-size: contain;
  position: absolute;
  inset: 0;
}

#section1 .social-link-instagram:after {
  content: "";
  background-image: url("instagram.19c3dd95.svg");
  background-size: contain;
  position: absolute;
  inset: 0;
}

#section1 .social-link-facebook:after {
  content: "";
  background-image: url("facebook.488d24a6.svg");
  background-size: contain;
  position: absolute;
  inset: 0;
}

#section1 .social-link-youtube:after {
  content: "";
  background-image: url("youtube.5d62d272.svg");
  background-size: contain;
  position: absolute;
  inset: 0;
}

#section2 {
  background-color: #313131;
  background-image: url("dammy_2.451b6333.png");
  background-position: 100% 0;
  background-size: cover;
  justify-content: stretch;
  display: flex;
}

@media (width <= 599px) {
  #section2 {
    flex-direction: column-reverse;
    align-items: center;
  }
}

#section2:after {
  content: "";
  opacity: .1;
  z-index: 3;
  pointer-events: none;
  background-image: url("dammy_smiley.638c24bd.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#section2 .left {
  flex-grow: 1;
  justify-content: start;
  align-items: end;
  padding: 3em;
  display: flex;
  position: relative;
}

@media (width <= 599px) {
  #section2 .left {
    flex-grow: 0;
    width: 0;
    display: none;
  }
}

#section2 .left .logo {
  width: 30vw;
}

#section2 .right {
  z-index: 1;
  box-sizing: border-box;
  background-color: #313131;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  max-width: 40vw;
  height: 200%;
  padding: 5vh 6vw;
  display: flex;
  position: relative;
}

@media (width <= 599px) {
  #section2 .right {
    max-width: 100vw;
    height: 100vh;
    display: block;
  }

  #section2 .right h2 {
    text-align: center;
  }
}

#section2 .right .balken {
  height: 100%;
  position: absolute;
  top: 0;
  left: 2px;
  transform: translateX(-100%);
}

#section3 {
  overflow: hidden;
}

#section3 .video, #section3 .overlay {
  filter: brightness(.5);
  z-index: -1;
  height: 100%;
  position: absolute;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
}

#section3 .content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5em;
  display: flex;
  transform: scale(.001);
}

@media (width <= 599px) {
  #section3 .content {
    font-size: 1em;
    transform: none;
  }
}

#section4 {
  background-image: url("dammy_3.556af692.png");
  background-position: top;
  background-size: cover;
  flex-direction: column;
  justify-content: space-around;
  width: 200%;
  max-height: 100vh;
  display: flex;
  overflow: hidden;
}

@media (width <= 599px) {
  #section4 {
    max-height: unset;
    width: 100%;
    height: auto;
    overflow: visible;
  }
}

#section4 .top {
  justify-content: end;
  align-items: start;
  padding: 2em;
  display: flex;
  position: relative;
}

@media (width <= 599px) {
  #section4 .top {
    display: none;
  }
}

#section4 .top .logo {
  width: 40vw;
  min-width: 200px;
}

#section4:after {
  content: "";
  opacity: .1;
  z-index: 3;
  pointer-events: none;
  background-image: url("dammy_smiley.638c24bd.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

#section4 .bottom {
  z-index: 1;
  background-color: #313131;
  width: 100%;
  height: 100%;
  transition: all .8s;
  display: flex;
  position: relative;
  transform: translate(0, 50%);
}

@media (width <= 599px) {
  #section4 .bottom {
    height: min-content;
    margin-top: 20em;
    display: block;
    transform: none;
  }
}

#section4 .bottom > * {
  width: 100%;
  height: 100%;
}

#section4 .bottom .balken {
  z-index: 2;
  width: 100%;
  height: auto;
  position: absolute;
  top: 2px;
  left: -1px;
  right: -1px;
  transform: translateY(-80%);
}

#section4 .bottom ul > li {
  list-style: none;
}

#section4 .bottom-part {
  z-index: 2;
  justify-content: space-evenly;
  align-items: start;
  max-width: 100vw;
  height: 100%;
  padding: 3vh 8vw;
  display: flex;
}

#section4 .bottom-part h2 {
  text-align: center;
  display: block;
}

#section4 .bottom-part1 {
  margin-bottom: 20px;
}

#section4 .bottom-part2 {
  margin-top: -50px;
}

@media (width <= 599px) {
  #section4 .video {
    display: none;
  }
}

#section5 {
  background-color: #313131;
  justify-content: flex-start;
  height: 100%;
  display: flex;
}

#section5 .left {
  z-index: 1;
  background-color: #313131;
  align-items: end;
  max-width: 15%;
  padding: 3vh 5vw;
  display: flex;
  position: relative;
  transform: translateX(50%);
}

#section5 .left .links {
  z-index: 2;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 3em;
  font-size: 1.4em;
  line-height: 2;
  display: flex;
}

#section5 .left .balken {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2px;
  transform: scaleX(-1)translateX(-100%);
}

#section5 .right {
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
  min-width: 10%;
  height: 100%;
  padding: 1em 3em;
  display: flex;
  position: relative;
  overflow: hidden;
}

#section5 .right .bg {
  will-change: transform;
  background-image: url("dammy_4.3edec63e.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (width <= 599px) {
  #section5 .right {
    align-items: start;
    padding: 1em .5em;
  }
}

#section5 .right .logo-wrapper {
  width: 60%;
}

/*# sourceMappingURL=index.4862d6c6.css.map */
