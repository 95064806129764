#section2 {
    display: flex;
    justify-content: stretch;
    background-color: #313131;
    background-image: url('../static/dammy_2.png');
    background-size: cover;
    background-position: right top;
    @include mobile() {
        flex-direction: column-reverse;
        align-items: center;
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../static/dammy_smiley.png');
        opacity: .1;
        background-size: cover;
        z-index: 3;
        pointer-events: none;
    }
    .left {
        display: flex;
        justify-content: start;
        align-items: end;
        position: relative;
        padding: 3em;
        flex-grow: 1;
        @include mobile() {
            display: none;
            width: 0;
            flex-grow: 0;
        }
        .logo {
            width: 30vw;
        }
    }
    .right {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #313131;
        padding: 5vh 6vw;
        z-index: 1;
        max-width: 40vw;
        box-sizing: border-box;
        flex-direction: column-reverse;
        height: 200%;
        @include mobile {
            height: 100vh;
            max-width: 100vw;
            display: block;
            & h2 {
                text-align: center;
            }
        }
        .balken {
            position: absolute;
            left: 2px;
            top: 0;
            height: 100%;
            transform: translateX(-100%);
        }
    }
}