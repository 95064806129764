#section3 {
    overflow: hidden;
    .video, .overlay {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        filter: brightness(.5);
        z-index: -1;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        font-size: 1.5em;
        transform: scale(.001);
        text-align: center;
        @include mobile() {
            font-size: 1em;
            transform: none;
        }
    }
}