@import 'responsive';

#section1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1vh 4vw;
    max-width: 100%;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url('../static/dammy_bg.png');
        background-size: cover;
    }

    .dammy {
        max-height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;

        @include mobile() {
            max-width: 120vw;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        max-height: 700px;
        height: 80%;
        text-align: right;
        position: relative;
        transform: translateX(400%);
        @include mobile() {
            text-align: center;
            align-items: center;
            width: 100%;
            height: 55%;
            margin: -10% auto 0;
            transform: none;
        }

        .logo-wrapper {
            width: 100%;
        }

        .subline {
            font-size: 2em;
            font-weight: 100;
            font-family: 'Raleway';
        }
    }

    $socials: 'spotify', 'instagram', 'facebook', 'youtube';

    .social {
        display: flex;
        height: auto;
        align-items: center;

        @include mobile() {
            justify-content: center;
        }

        &-link {
            width: 3em;
            height: 3em;
            margin: .5em;
            color: transparent;
            position: relative;
            transition: all ease .4s;

            &:hover {
                transform: scale(1.1);
            }

            @each $social in $socials {
                &-#{$social} {
                    &::after {
                        position: absolute;
                        inset: 0;
                        content: '';
                        background-image: url('../static/socials/#{$social}.svg');
                        background-size: contain;
                    }
                }
            }
        }
    }

}