/*
* Variables for breakpoints
*/
$mobile-landscape: 568px;
$tablet: 768px;
$desktop: 992px;

/*
* Map for breakpoints
*/
$breakpoints: (
  "small": (
    min-width: $mobile-landscape,
  ),
  "medium": (
    min-width: $tablet,
  ),
  "large": (
    min-width: $desktop,
  ),
); 

@mixin mobile {
      @media (max-width: 599px) {
        @content;
    }
  }