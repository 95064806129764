#section5 {
    display: flex;
    background-color: #313131;
    justify-content: flex-start;
    height: 100%;
    .left {
        position: relative;
        display: flex;
        align-items: end;
        padding: 3vh 5vw;
        background-color: #313131;
        max-width: 15%;
        transform: translateX(50%);
        z-index: 1;
        //width: 100%;
        .links {
            font-size: 1.4em;
            line-height: 2;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-bottom: 3em;
        }
        .balken {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 2px;
            height: 100%;
            transform: scaleX(-1) translateX(-100%);
        }
    }
    .right {
        height: 100%;
        width: 100%;
        min-width: 10%;
        display: flex;
        flex-direction:row;
        justify-content: flex-end;
        align-items: end;
        padding: 1em 3em;
        position: relative;
        overflow: hidden;
        .bg {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-image: url('../static/dammy_4.png');
            background-size: cover;
            background-position: center;
            will-change: transform;
        }
        @include mobile {
            align-items: start;
            padding: 1em 0.5em;
        }
        .logo-wrapper {
            width: 60%;
        }
    }
}