@font-face {
    font-family: 'Raleway';
    src: url(/src/static/fonts/Raleway.ttf);
}
body {
    background: #000;
    color: #fff;
    font-family: 'Raleway';
    font-size: 20px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    & * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        will-change: filter, transform;
    }
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    margin-bottom: 1em;
}

p {
    width: inherit;
    word-wrap: break-word;
    margin-bottom: 1em;
}

a {
    color: #fff;
    text-decoration: none;
    transition: all ease .4s;
    cursor: pointer;
    &:hover {
        text-shadow: red .1em 0 .4em;
    }
}

.wrapper {
    //scroll-snap-type: y mandatory;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.section {
    height: 100vh;
    width: 100vw;
    inset: 0;
    position: relative;
    box-sizing: border-box;
    //scroll-snap-align: start;
    overflow: hidden;
}

.logo {
    position: relative;
    padding-bottom: 18.3%; /* ratio of img */
    max-width: 100%;
    &::after {
        content: '';
        position: absolute;
        background-image: url('../static/dammy_logo.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        inset: 0;
        max-width: 100%;
    }
}

.upper {
    text-transform: uppercase;
}