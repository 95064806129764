#section4 {
    display: flex;
    flex-direction: column;
    background-image: url('../static/dammy_3.png');
    background-size: cover;
    background-position: center top;
    overflow: hidden;
    width: 200%;
    max-height: 100vh;
    justify-content: space-around;
    @include mobile {
        width: 100%;
        max-height: unset;
        overflow: visible;
        height: auto;
    }
    .top {
        display: flex;
        justify-content: end;
        align-items: start;
        position: relative;
        padding: 2em;
        @include mobile {
            display: none;
        }
        .logo {
            width: 40vw;
            min-width: 200px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../static/dammy_smiley.png');
        opacity: .1;
        background-size: cover;
        z-index: 3;
        pointer-events: none;
    }
    .bottom {
        background-color: #313131;
        transition: all ease .8s;
        display: flex;
        z-index: 1;
        position: relative;
        width: 100%;
        transform: translate(0, 50%);
        height: 100%;
        @include mobile {
            height: min-content;
            display: block;
            transform: none;
            margin-top: 20em;
        }
        & > * {
            width: 100%;
            height: 100%;
        }
        .balken {
            position: absolute;
            top: 2px;
            right: -1px;
            left: -1px;
            width: 100%;
            transform: translateY(-80%);
            height: auto;
            z-index: 2;
        }
        & ul > li {
            list-style: none;
        }
        &-part {
            padding: 3vh 8vw;
            max-width: 100vw;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: start;
            z-index: 2;
            & h2 {
                display: block;
                text-align: center;
            }
            &1 {
                margin-bottom: 20px;
            }
            &2 {
                margin-top: -50px;
            }
        }
    }
    @include mobile () {
        .video {
            display: none;
        }
    }
}